@import '../../styles/variables.scss';

.privacy {
    &__links {
        text-decoration: underline;
        text-decoration-color: red;

        &:hover {
            color: red;
            text-decoration: underline;
            text-decoration-color: red;
        }
    }
}