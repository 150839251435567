@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}p{color:#000}body{background-color:white;font-family:'Ubuntu Mono', monospace}section{max-width:1200px;padding:30px 40px 10px}a{color:#000;display:inline-block}a.button{text-transform:uppercase;background-color:transparent;border:3px solid #000;text-decoration:none;font-weight:bold;position:relative}a.button:before{content:'';position:absolute;top:0;left:0;width:0%;height:100%;background-color:#fff;z-index:-1;transition:0.4s}@media screen and (max-width: 500px){a.button:before{width:100%}}a.button:hover:before{width:100%}h2.sectionHeading{font-size:28px;font-weight:700;color:#fff;background-color:#000;display:inline-block;padding:10px 20px;margin:25px 0;transform:rotate(-3deg)}.navbar-dark .navbar-nav .nav-link{color:white;text-align:right}.navbar-dark .navbar-nav>li>a:hover{text-decoration:underline;color:white !important}button:focus{outline:none}@media (min-width: 576px) and (orientation: landscape){.container{max-width:700px}}@media (min-width: 577px) and (max-width: 767px){.container{max-width:700px}}@media (min-width: 768px) and (orientation: landscape){.container{max-width:770px}}@media (min-width: 769px) and (max-width: 823px){.container{max-width:770px}}@media (min-width: 824px) and (max-width: 991px){.container{max-width:900px}}@media (min-width: 992px) and (orientation: landscape){.container{max-width:960px}}@media (min-width: 1200px) and (orientation: landscape){.container{max-width:1140px}}

a {
}

a:hover {
    color: red;
    text-decoration: none;
}

.profile-pic {
    height: 4vmin;
    margin-right: 10px;
    border-radius: 50px;
}

div.media {
    margin-top: 30px;
    margin-bottom: 10px;
}

div.media .media-heading .jobTotalDuration {
    color: #666;
    font-size: 14px;
    display: block;
}

div.media img {
    height: 10vmin;
    margin-right: 10px;
    border-radius: 50px;
}

div.media .jobDuration {
    color: #444;
    display: block;
}

div.media .jobLocation {
    color: #666;
}

.formLabel {
    font-weight: bold;
}

.certificateLink {
    color: cornflowerblue;
}

footer {
    margin-top: 20px;
    margin-bottom: 10px;
    border-top: #444444;
    text-align: center;
}

footer a {
    color: black;
    font-size: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.navigation__navbar{background-color:black}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.posts__color{color:#767676}.posts__image{border-radius:10px !important}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.talks__color{color:#767676}.talks__image{border-radius:10px !important}.talks__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.talks__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

.centeredContainer,.projects__container{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.projects__container{padding:0 20px 30px;margin-top:5px}@media screen and (max-width: 500px){.projects__container{padding:0 40px;margin-top:10px}}.projects__heading{border:2px solid #fff}.projects__tiles{display:flex;flex-wrap:wrap;justify-content:center}.projects__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.projects__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.gitHubStats__container{display:flex;flex-direction:row;flex:1 1;background-color:white;padding:10px;cursor:pointer}.gitHubStats__container:hover>*>*{color:#3c3c3c}.gitHubStats__stat{flex:1 1;display:flex;flex-direction:column;align-items:center;text-align:center}.gitHubStats__icon{color:#555;font-size:16px}.gitHubStats__description{color:#767676;font-size:14px;margin-top:10px}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.projectTile__container{display:flex;flex-direction:column;min-width:300px;width:30%;padding:20px;border:1px solid #ddd;margin:0 1.5% 30px;box-sizing:border-box;transition:.2s;background-color:#f7f7f7}.projectTile__container .gitHubStats__container{transition:background-color .2s}.projectTile__container:hover{background-color:#f7f7f7;box-shadow:0px 0px 8px rgba(0,0,0,0.2)}.projectTile__container:hover .gitHubStats__container{background-color:#fff}@media (min-width: 992px){.projectTile__container{min-width:200px}}@media screen and (max-width: 767px){.projectTile__container{width:80%}}@media screen and (max-width: 500px){.projectTile__container{background-color:#f7f7f7}.projectTile__container .gitHubStats__container{background-color:#fff}}.projectTile__heading{margin:0;text-transform:uppercase;font-size:18px;padding-bottom:5px;border-bottom:2px solid #fff;margin-bottom:5px;display:flex;align-items:center;font-weight:700}.projectTile__title{flex:1 1;color:black}.projectTile__logo{height:30px}.projectTile__description{font-size:14px;line-height:1.4;color:#767676}.projectTile__videoButton{border:0;background-color:transparent;padding:0;margin:10px 0;font-style:italic;font-weight:700;font-size:14px;color:#767676;cursor:pointer}.projectTile__github{background-color:#fff}.projectTile__content{display:flex;flex-direction:row;flex:1 1;align-items:flex-end;margin-top:5px}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.profilesummary__container{position:relative;top:40px}@media screen and (max-width: 500px) and (orientation: portrait){.profilesummary__container{font-size:0.8em}.profilesummary__container .profilesummary__display_4{font-size:25px}}@media screen and (max-width: 750px) and (orientation: landscape){.profilesummary__container{font-size:0.8em}.profilesummary__container .profilesummary__display_4{font-size:43px}}.profilesummary__display_4{font-size:3.5em;font-weight:300;line-height:1.2}.profilesummary__lead{font-size:1.25em;font-weight:300;color:#555}.profilesummary__header{background-image:linear-gradient(rgba(255,255,255,0.1), #fff),url(/static/media/header.9949cb84.jpg);background-size:cover}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.about__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.about__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}@media screen and (min-width: 600px){.home__row{margin-top:1rem}}.home__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.home__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.imprint__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.imprint__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

.centeredContainer{margin:0 auto;width:100%;max-width:1200px;padding:0 40px;box-sizing:border-box}.privacy__links{text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}.privacy__links:hover{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red}

