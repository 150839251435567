@import '../../styles/variables.scss';

.posts {
    &__color {
        color: $darkgray;
    }

    &__image {
        border-radius: 10px !important;
    }
}
