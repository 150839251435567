@import '../../styles/variables.scss';

.home {
    &__row {
        @media screen
          and (min-width: 600px)
        {
          margin-top: 1rem;
        }
    }

    &__links {
        text-decoration: underline;
        text-decoration-color: red;

        &:hover {
            color: red;
            text-decoration: underline;
            text-decoration-color: red;
            }
    }
}
