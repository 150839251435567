@import '../../styles/variables.scss';

.profilesummary {
    &__container {
        position: relative;
        top: 40px;

        @media screen
          and (max-width : 500px)
          and (orientation: portrait)
        {
            font-size: 0.8em;

            .profilesummary__display_4 {
                font-size: 25px;
            }
        }

        @media screen
          and (max-width : 750px)
          and (orientation: landscape)
        {
            font-size: 0.8em;

            .profilesummary__display_4 {
                font-size: 43px;
            }
        }

    }

    &__display_4 {
        font-size: 3.5em;
        font-weight: 300;
        line-height: 1.2;
    }

    &__lead {
        font-size: 1.25em;
        font-weight: 300;
        color: $darkestgray;
    }

    &__header {
        background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,1)), url('../../static/header.jpg');
        background-size: cover;
    }
}
