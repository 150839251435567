@import '../../styles/variables.scss';

.about {
    &__links {
        text-decoration: underline;
        text-decoration-color: red;

        &:hover {
            color: red;
            text-decoration: underline;
            text-decoration-color: red;
            }
    }
}
