@import '../../styles/variables.scss';

.talks {
    &__color {
        color: $darkgray;
    }

    &__image {
        border-radius: 10px !important;
    }

    &__links {
        text-decoration: underline;
        text-decoration-color: red;

        &:hover {
            color: red;
            text-decoration: underline;
            text-decoration-color: red;
            }
    }
}
