@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import './variables';

p {
    color: $font-color-primary;
}

body {
    background-color: white;
    font-family: 'Ubuntu Mono', monospace;
}

section {
    max-width: 1200px;
    padding: 30px 40px 10px;
}

a {
    color: $font-color-primary;
    display: inline-block;

}

a.button {
    text-transform: uppercase;
    background-color: transparent;
    border: 3px solid #000;
    text-decoration: none;
    font-weight: bold;

    position: relative;


    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #fff;
        z-index: -1;
        transition: 0.4s;

        @media screen and (max-width : 500px) {
            width: 100%;
        }
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

h2.sectionHeading {
    font-size: 28px;
    font-weight: 700;
    color: $font-color-secondary;
    background-color: $background-color-secondary;
    display: inline-block;
    padding: 10px 20px;
    margin: 25px 0;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

/* Add custom boostrap css */
.navbar-dark .navbar-nav .nav-link {
    color: white;
    text-align:right
}

.navbar-dark .navbar-nav > li > a:hover {
    text-decoration: underline;
    color: white !important;
}

button:focus {
  outline: none;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (orientation: landscape) {
    .container {
        max-width: 700px;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .container {
        max-width: 700px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (orientation: landscape) {
    .container {
        max-width: 770px;
    }
}

// Optimize breakpoints for Pixel 2 XL, iPhone X
@media (min-width: 769px) and (max-width: 823px) {
    .container {
        max-width: 770px;
    }
}

@media (min-width: 824px) and (max-width: 991px) {
    .container {
        max-width: 900px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (orientation: landscape) {
    .container {
        max-width: 960px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (orientation: landscape) {
    .container {
        max-width: 1140px;
    }
}
