@import '../../styles/variables.scss';

.projects{
    &__container {
        @extend .centeredContainer;
        padding: 0 20px 30px;
        margin-top: 5px;

        @media screen and (max-width : 500px) {
            padding: 0 40px;
            margin-top: 10px;
        }
    }

    &__heading {
        border: 2px solid #fff;
    }

    &__tiles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__links {
        text-decoration: underline;
        text-decoration-color: red;

        &:hover {
            color: red;
            text-decoration: underline;
            text-decoration-color: red;
            }
    }
}
