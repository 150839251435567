@import '../../styles/variables.scss';

.gitHubStats {
    &__container {
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: white;
        padding: 10px;
        cursor: pointer;

        &:hover {
            > * > * {
                color: darken($darkestgray, 10%);
            }
        }
    }

    &__stat {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__icon {
        color: $darkestgray;
        font-size: 16px;
    }

    &__description {
        color: $darkgray;
        font-size: 14px;
        margin-top: 10px;
    }
}
